exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-all-works-js": () => import("./../../../src/pages/AllWorks.js" /* webpackChunkName: "component---src-pages-all-works-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-oonservices-js": () => import("./../../../src/pages/Oonservices.js" /* webpackChunkName: "component---src-pages-oonservices-js" */),
  "component---src-pages-oonstory-js": () => import("./../../../src/pages/oonstory.js" /* webpackChunkName: "component---src-pages-oonstory-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-person-details-js": () => import("./../../../src/templates/person-details.js" /* webpackChunkName: "component---src-templates-person-details-js" */)
}

